import React, { useEffect, useRef, useState } from 'react';

const ScrollAnimation = ({ threshold = 100, animationDuration = 0.5, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  const handleScroll = () => {
    if (!elementRef.current) return
    const element = elementRef.current;
    const top = element.getBoundingClientRect().top;
    const isVisible = top < window.innerHeight - threshold;

    setIsVisible(isVisible);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const animationStyles = {
    transition: `opacity ${animationDuration}s, transform ${animationDuration}s`,
    opacity: isVisible ? 1 : 0,
    transform: `translateY(${isVisible ? '0' : '20px'})`,
  };

  return (
    <div className="scroll-animation" ref={elementRef} style={animationStyles}>
      {children}
    </div>
  );
};

export default ScrollAnimation;
