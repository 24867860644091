import React from 'react';

import Slider from "react-slick";

const TestimonialSlider = () => { 

  const testimonials = [
    {
      id: 1,
      name: 'Life-Changing App! - Sarah M.',
      content: 'VaSi has completely transformed the way I manage my tasks and stay organized. It\'s intuitive, efficient, and keeps me on track. Love it!',
    },
    {
      id: 2,
      name: 'Highly Effective Tool! - John D.',
      content: 'VaSi has revolutionized my productivity. It\'s like having a personal assistant at my fingertips. I\'m more organized and accomplishing more than ever before!',
    },
    {
      id: 3,
      name: 'Game-Changer for Productivity! - Emily R.',
      content: 'VaSi has been a game-changer for me. It helps me prioritize tasks, set reminders, and stay focused on my goals. It\'s an essential part of my daily routine.',
    },
    {
      id: 4,
      name: 'Boosted My Efficiency! - Mark S.',
      content: 'Using VaSi has significantly boosted my efficiency. It keeps me on track, helps me manage my time effectively, and ensures I never miss important deadlines.',
    },
    {
      id: 5,
      name: 'Improved Work-Life Balance! - Jessica W.',
      content: 'VaSi has transformed how I manage my work and personal life. It helps me stay organized, prioritize tasks, and strike a better work-life balance. Highly recommended!',
    },
    {
      id: 5,
      name: 'Best Productivity App Ever! - David L.',
      content: 'I\'ve tried many productivity apps, but VaSi stands out from the rest. It\'s intuitive, user-friendly, and packed with features that truly enhance my productivity. Love it!',
    }
    // Add more testimonials as needed
  ];


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 3000,  
    pauseOnHover: true   
};

  return (
    
    <Slider {...settings}
    >
      {testimonials.map((testimonial) => (
        <div key={testimonial.id}>
          <div className="elementor-testimonial-content">{testimonial.name}</div>
          <div className="elementor-testimonial-meta elementor-has-image elementor-testimonial-image-position-aside">
            <div className="elementor-testimonial-meta-inner">
              {/* <div className="elementor-testimonial-image">

                <img decoding="async" width="60" height="60" src="https://socialbee.com/wp-content/uploads/2020/03/neal-schaffer.jpg" alt="neal schaffer" />
              </div> */}

              <div className="elementor-testimonial-details">
                {/* <div className="elementor-testimonial-name">&nbsp;</div> */}
                <div className="elementor-testimonial-job">{testimonial.content}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TestimonialSlider;
