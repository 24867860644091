import React, { useEffect } from 'react';
import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import HeaderLoggedOut from './HeaderLoggedOut'
import FooterNew from './FooterNew'
import VideoCarousel from './VideoCarousel'
import TestimonialSlider from './TestimonialSlider'
import SchemaPage from './SchemaPage'

//import Typewriter from './Typewriter';
//import { Link } from "react-router-dom"
import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'

import img_Apple_Store from "../assets/images/app-store-badge.svg"
import img_Play_Store from "../assets/images/google-play-badge.svg"
import FeaturedDotsCarousel from './FeaturedDotsCarousel';
import createDotImg from '../assets/images/create_a_dot.png'
import askVasi from '../assets/images/ask_vasi.png'
import vasiReminder from '../assets/images/vasi_reminder.png'
import vasiMarketplace from '../assets/images/vasi_marketplace.png'
import vasiSearch from '../assets/images/vasi_search.png'


//import SocialProof from '../assets/images/SocialProof.webp'
//import WritewithAI from '../assets/images/Write-with-AI.webp'
import ScrollAnimation from './ScrollAnimation';

import TextSlider from './TextSlider';
import BoxFocus from './BoxFocus';

function LandingPageNew() {
    const playStoreLink = "https://play.google.com/store/apps/details?id=com.learnee.vasi"
    const appStoreLink = "https://apps.apple.com/us/app/VaSi-an-extension-of-your-mind/id1488476877"

    const texts = ['VaSi!', 'Your Productivity Partner!', 'Your AI Assistant!'];
    const interval = 3000;
    const duration = 2000;


    useEffect(() => {
        document.title = `VaSi | Your Productivity Partner!`       
        //ReactGA.pageview(window.location.pathname + window.location.search);
        window.scrollTo(0, 0)
    }, [])

    const gaEventTracker = useAnalyticsEventTracker('Landing Page');

    // const scrollToSection = (id) => {
    //     const element = document.getElementById(id);
    //     element.scrollIntoView({ behavior: 'smooth' });
    // };

    //console.log(gaEventTracker);
    return (

        <div className="home inner_pages_nav elementor-default elementor-kit-6677 elementor-page elementor-page-4722">

            <a className="skip-link screen-reader-text" href="#content">
                Skip to content</a>

            <HeaderLoggedOut />
            <main id="content" className="site-main post-4722 page type-page status-publish has-post-thumbnail hentry" role="main">
                <div className="page-content">
                    <div data-elementor-type="wp-page" data-elementor-id="4722" className="elementor elementor-4722">
                        <section className="elementor-section elementor-top-section elementor-element elementor-element-a363456 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a363456" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;shape_divider_bottom&quot;:&quot;tilt&quot;}">
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                                </svg>		</div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-8a859f6" data-id="8a859f6" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-3afab82 elementor-widget elementor-widget-heading" data-id="3afab82" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                {/* className="elementor-heading-title elementor-size-default" */}
                                                <span style={{ position: "relative" }} className="elementor-heading-title elementor-size-default">
                                                    {/* <span id="marqueeText"><span>Welcome to VaSi!</span></span> */}
                                                    {/* Welcome to&nbsp;
                                                    <span id="textContainer">
                                                        <span id="scrollingText"> VaSi!</span>
                                                    </span> */}
                                                    <span className='font-size-50'> Welcome to </span> <TextSlider texts={texts} interval={interval} duration={duration} />
                                                </span>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-6a28035 elementor-widget elementor-widget-text-editor" data-id="6a28035" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                Step into a world of boundless productivity with VaSi, the ultimate companion app designed to empower you to be at your best. Get ready to embark on a journey of efficiency, organization, and personal growth like never before.

                                            </div>
                                        </div>

                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-28e0577 elementor-section-full_width elementor-section-height-default elementor-section-height-default" data-id="28e0577" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-narrow">
                                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-5b63ea3" data-id="5b63ea3" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-2c213f9 elementor-align-right elementor-mobile-align-justify elementor-widget elementor-widget-button" data-id="2c213f9" data-element_type="widget" aria-label="Start Using VaSi for Free" data-widget_type="button.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-button-wrapper animate__animated  animate__pulse">
                                                                    <a onClick={() => gaEventTracker('App Store')} target="_blank" rel="noreferrer" className="me-lg-3 mb-lg-0 app-btn" href={`${appStoreLink}`}><img className="app-badge-apple" src={img_Apple_Store} alt="..." /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-ac5a923" data-id="ac5a923" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-b220546 elementor-align-left elementor-mobile-align-justify elementor-widget elementor-widget-button" data-id="b220546" data-element_type="widget" aria-label="Book a VaSi Demo" data-widget_type="button.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-button-wrapper animate__animated  animate__pulse">
                                                                    <a onClick={() => gaEventTracker('Play Store')} target="_blank" rel="noreferrer" href={`${playStoreLink}`} className='app-btn' ><img className="app-badge-play" src={img_Play_Store} alt="..." /></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <div className="elementor-element elementor-element-12cb26d elementor-widget elementor-widget-global elementor-global-43819 elementor-widget-image" data-id="12cb26d" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container">
                                                <figure className="wp-caption">

                                                    {/* <img decoding="async" width="768" height="109" src={SocialProof} alt="The people that love VaSi" /> */}

                                                    <figcaption className="widget-image-caption wp-caption-text">Welcome to a world where productivity meets ingenuity. Welcome to VaSi, where you'll discover endless possibilities, one Dot at a time. Join us today and let's embark on a journey of personal growth together. </figcaption>
                                                </figure>
                                            </div>
                                        </div>

                                        {/* <div className="elementor-element elementor-element-f06e043 elementor-widget elementor-widget-text-editor padding-top-20" data-id="f06e043" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                Unlock Your Potential, Dot by Dot!
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>




                        <section className="elementor-section elementor-top-section elementor-element elementor-element-1570105 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="1570105" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-fe04227" data-id="fe04227" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-77e4ac0 elementor-widget elementor-widget-heading" data-id="77e4ac0" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <h2 className="elementor-heading-title elementor-size-default">Unveiling the Vision: A Conversation with Our Founders</h2>
                                                </ScrollAnimation>


                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-0335699 elementor-widget elementor-widget-text-editor" data-id="0335699" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>Discover the Inspiration behind VaSi: Empowering Your Productivity Journey.</p>
                                                </ScrollAnimation>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-4d401f5" data-id="4d401f5" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-1dac062 elementor-widget__width-initial elementor-widget elementor-widget-html" data-id="1dac062" data-element_type="widget" data-widget_type="html.default">
                                            <div className="elementor-widget-container">

                                                <VideoCarousel />

                                                {/* <iframe src="https://www.youtube.com/embed/tzn_z9Q_oWQ" frameBorder="0" loading="lazy" style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", colorScheme: "light" }} title="VaSi"></iframe> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>


                        <section className="elementor-section elementor-top-section elementor-element elementor-element-a92c563 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a92c563" data-element_type="section" data-settings="{&quot;shape_divider_bottom&quot;:&quot;tilt&quot;,&quot;background_background&quot;:&quot;classNameic&quot;}">
                            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                                </svg>
                            </div>
                            {/* <div className="elementor-container elementor-column-gap-default"> */}

                            <BoxFocus />
                            {/* 
                                <div className="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-839dad5" data-id="839dad5" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">

                                        <div className="elementor-element elementor-element-c422c1d elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c422c1d" data-element_type="widget" data-widget_type="icon-box.default">
                                            <div className="elementor-widget-container height-260">
                                                <div className="elementor-icon-box-wrapper">
                                                    <div className="elementor-icon-box-icon">
                                                        <Link to="#" onClick={() => scrollToSection('section1')} className="elementor-icon elementor-animation-" rel="noreferrer" aria-label="Manage your social media profiles">
                                                            <img decoding="async" src={Create} alt="Create" />
                                                        </Link>
                                                    </div>
                                                    <div className="elementor-icon-box-content animate__animated animate__bounce animate__delay-2s">

                                                        <h2 className="elementor-icon-box-title">

                                                            Create Dots
                                                        </h2>
                                                        <p className="elementor-icon-box-description">
                                                            They are like little pieces of your mind.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-9abc3bb" data-id="9abc3bb" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-353f4ad elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="353f4ad" data-element_type="widget" data-widget_type="icon-box.default">
                                            <div className="elementor-widget-container height-260">
                                                <div className="elementor-icon-box-wrapper">
                                                    <div className="elementor-icon-box-icon">

                                                        <Link className="elementor-icon elementor-animation-" to="#" onClick={() => scrollToSection('section2')} rel="noreferrer" aria-label="Create social media content">
                                                            <img decoding="async" src={AI} alt="AI" />
                                                        </Link>
                                                    </div>
                                                    <div className="elementor-icon-box-content animate__animated animate__bounce animate__delay-2s">
                                                        <h2 className="elementor-icon-box-title">

                                                            AskVasi
                                                        </h2>
                                                        <p className="elementor-icon-box-description">
                                                            Your AI partner for creativity.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-c008481" data-id="c008481" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-3163703 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="3163703" data-element_type="widget" data-widget_type="icon-box.default">
                                            <div className="elementor-widget-container height-260">
                                                <div className="elementor-icon-box-wrapper">
                                                    <div className="elementor-icon-box-icon">

                                                        <Link className="elementor-icon elementor-animation-" to="#" onClick={() => scrollToSection('section3')} rel="noreferrer" aria-label="Schedule social media content">

                                                            <img decoding="async" src={Reminders} alt="Reminders" />
                                                        </Link>
                                                    </div>
                                                    <div className="elementor-icon-box-content animate__animated animate__bounce animate__delay-3s">
                                                        <h2 className="elementor-icon-box-title">

                                                            Reminders
                                                        </h2>
                                                        <p className="elementor-icon-box-description">
                                                            Whenever and wherever you need.					</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-e0230c9" data-id="e0230c9" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-53e3870 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="53e3870" data-element_type="widget" data-widget_type="icon-box.default">
                                            <div className="elementor-widget-container height-260">
                                                <div className="elementor-icon-box-wrapper">
                                                    <div className="elementor-icon-box-icon">

                                                        <Link className="elementor-icon elementor-animation-" to="#" onClick={() => scrollToSection('section4')} rel="noreferrer" aria-label="Publish social media content">
                                                            <img decoding="async" src={Market} alt="Marketplace" />
                                                        </Link>
                                                    </div>
                                                    <div className="elementor-icon-box-content animate__animated animate__bounce animate__delay-3s">
                                                        <h2 className="elementor-icon-box-title">

                                                            Marketplace
                                                        </h2>
                                                        <p className="elementor-icon-box-description">
                                                            Buy dots to make life easier.					</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-d34fbac" data-id="d34fbac" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-9045622 elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="9045622" data-element_type="widget" data-widget_type="icon-box.default">
                                            <div className="elementor-widget-container height-260">
                                                <div className="elementor-icon-box-wrapper">
                                                    <div className="elementor-icon-box-icon">
                                                        <Link className="elementor-icon elementor-animation-" to="#" onClick={() => scrollToSection('section5')} rel="noreferrer" aria-label="Analyze your social media performance">

                                                            <img decoding="async" src={Sell} alt="Sell" />

                                                        </Link>
                                                    </div>
                                                    <div className="elementor-icon-box-content animate__animated animate__bounce animate__delay-4s ">
                                                        <h2 className="elementor-icon-box-title">

                                                            Sell
                                                        </h2>
                                                        <p className="elementor-icon-box-description">
                                                            Sell dots to earn credits.				</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}


                            {/* </div> */}
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-bcab5db elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="bcab5db" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                            <div className="elementor-container elementor-column-gap-extended padding-4">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-96a1d50" data-id="96a1d50" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-6c5c9d5 elementor-widget elementor-widget-global elementor-global-12796 elementor-widget-heading" data-id="6c5c9d5" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default"><b>Featured Gems: Uncover the Most Popular Dots</b></h2>
                                                <h4>Explore our community driven collection of dots to elevate your growth journey
                                                </h4>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-9c9d904 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9c9d904" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                            <div className="elementor-container elementor-column-gap-extended">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-0902596" data-id="0902596" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-8a78fa2 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="8a78fa2" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">



                                                <FeaturedDotsCarousel />


                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>



                        <section className="elementor-section elementor-top-section elementor-element elementor-element-412ed8d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="412ed8d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;shape_divider_bottom&quot;:&quot;tilt&quot;}">
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                                </svg>		</div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e16ddfc" data-id="e16ddfc" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">


                                        <div className="elementor-element elementor-element-4f15c9a elementor-widget elementor-widget-heading" data-id="4f15c9a" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default"><b> Unleash Your Potential</b></h2>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-faa521f elementor-widget elementor-widget-text-editor" data-id="faa521f" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <p>“Productivity is never an accident. It is always the result of a commitment to excellence, intelligent planning, and focused effort.” – Paul J. Meyer
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>


                        {/* <section className="elementor-section elementor-top-section elementor-element elementor-element-412ed8d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="412ed8d" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;shape_divider_bottom&quot;:&quot;tilt&quot;}">
                            <div className="elementor-background-overlay"></div>
                            <div className="elementor-shape elementor-shape-bottom" data-negative="false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                                </svg>		</div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e16ddfc" data-id="e16ddfc" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-a06d42d elementor-widget elementor-widget-heading" data-id="a06d42d" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">Stay in the Loop. Follow Our Social Media Pages!</h2>		</div>
                                        </div>
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-d0eb175 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d0eb175" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default justify-content">
                                                <div className="elementor-column elementor-col-12 elementor-inner-column elementor-element elementor-element-6b469e9 " data-id="6b469e9" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-34082f3 elementor-widget elementor-widget-image" data-id="34082f3" data-element_type="widget" data-widget_type="image.default">
                                                            <div className="elementor-widget-container">
                                                                <a href="https://www.facebook.com/profile.php?id=100093084038569" rel="noreferrer">
                                                                    <img decoding="async" width="76" height="76" src={FacebookLogo} className="attachment-medium_large size-medium_large wp-image-4615" alt="Facebook" />

                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-b637324 elementor-widget elementor-widget-heading" data-id="b637324" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default"><a href="https://www.facebook.com/profile.php?id=100093084038569" rel="noreferrer" >Facebook</a></h3>		</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-12 elementor-inner-column elementor-element elementor-element-1861337" data-id="1861337" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-470b859 elementor-widget elementor-widget-image" data-id="470b859" data-element_type="widget" data-widget_type="image.default">
                                                            <div className="elementor-widget-container">
                                                                <a href="https://twitter.com/VaSi_Ai" rel="noreferrer" target="_blank">
                                                                    <img decoding="async" width="76" height="76" src={TwitterLogo} className="attachment-medium_large size-medium_large wp-image-4620" alt="Twitter" />								</a>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-dc7d32a elementor-widget elementor-widget-heading" data-id="dc7d32a" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default"><a href="https://twitter.com/VaSi_Ai" rel="noreferrer" target="_blank">Twitter</a></h3>		</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-12 elementor-inner-column elementor-element elementor-element-e22fe07" data-id="e22fe07" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-b3e3fe6 elementor-widget elementor-widget-image" data-id="b3e3fe6" data-element_type="widget" data-widget_type="image.default">
                                                            <div className="elementor-widget-container">
                                                                <a href="https://www.instagram.com/vasi.ai/" target="_blank" rel="noreferrer">
                                                                    <img decoding="async" width="77" height="76" src={InstaLogo} className="attachment-medium_large size-medium_large wp-image-4617" alt="Instagram" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-edc5926 elementor-widget elementor-widget-heading" data-id="edc5926" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default"><a href="https://www.instagram.com/vasi.ai/" rel="noreferrer" target="_blank">Instagram</a></h3>		</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-12 elementor-inner-column elementor-element elementor-element-45b4327" data-id="45b4327" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-08ee272 elementor-widget elementor-widget-image" data-id="08ee272" data-element_type="widget" data-widget_type="image.default">
                                                            <div className="elementor-widget-container">
                                                                <a href="https://www.linkedin.com/in/vasi-ai-301267272/" target="_blank" rel="noreferrer">
                                                                    <img decoding="async" width="77" height="76" src={LinkedInLogo} className="attachment-medium_large size-medium_large wp-image-4618" alt="Linkedin" />							</a>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-a4800fb elementor-widget elementor-widget-heading" data-id="a4800fb" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default"><a href="https://www.linkedin.com/in/vasi-ai-301267272/" rel="noreferrer" target="_blank">LinkedIn</a></h3>		</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="elementor-column elementor-col-12 elementor-inner-column elementor-element elementor-element-2619592" data-id="2619592" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-50d0a5d elementor-widget elementor-widget-image" data-id="50d0a5d" data-element_type="widget" data-widget_type="image.default">
                                                            <div className="elementor-widget-container">
                                                                <a href="https://www.youtube.com/channel/UCCkbhfPA7AjVh8VcR9SmuWg" rel="noreferrer" target="_blank">
                                                                    <img decoding="async" width="768" height="768" src={YoutubeLogo} className="attachment-medium_large size-medium_large wp-image-44882" alt="Rounded YouTube logo" />								</a>
                                                            </div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-66ed496 elementor-widget elementor-widget-heading" data-id="66ed496" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h3 className="elementor-heading-title elementor-size-default"><a href="https://www.youtube.com/channel/UCCkbhfPA7AjVh8VcR9SmuWg" rel="noreferrer" target="_blank">YouTube</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section> */}

                        <section className="elementor-section elementor-top-section elementor-element elementor-element-859a3b2 elementor-section-full_width elementor-hidden-tablet elementor-hidden-mobile elementor-section-height-default elementor-section-height-default" data-id="859a3b2" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b4a70cd" data-id="b4a70cd" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-0bea599 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0bea599" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default justify-content">
                                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-3348801" data-id="3348801" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-widget elementor-widget-heading text-center" data-id="648d0e6" data-element_type="widget" data-widget_type="heading.default">
                                                            <div className="elementor-widget-container">
                                                                <h2 className="elementor-heading-title elementor-size-default animate__animated animate__bounce animate__delay-2s">
                                                                    What Our Users Say</h2>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1f549de" data-id="1f549de" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-f30c5f8 elementor-widget elementor-widget-text-editor" data-id="f30c5f8" data-element_type="widget" data-widget_type="text-editor.default">
                                                            <div className="elementor-widget-container">
                                                                <p><a href="/love/"><strong>Explore more → </strong></a></p>						</div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </section>




                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-fc99626 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="fc99626" data-element_type="section">



                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-d6acd25" data-id="d6acd25" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-6695ed1" data-id="6695ed1" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-5ae988a elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box " data-id="5ae988a" data-element_type="widget" data-widget_type="icon-box.default">

                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>
                                                                        </span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>

                                                                        <p className="elementor-icon-box-description animate__animated animate__heartBeat">
                                                                            A must-have					</p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-dd3548b" data-id="dd3548b" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-70bb3db elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="70bb3db" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>

                                                                        </span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Very helpful app to lead a better life!				</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-ae0c1bf" data-id="ae0c1bf" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-9fcfa73 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="9fcfa73" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>


                                                                        </span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Excellent customer support					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-e6b9bc5" data-id="e6b9bc5" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-8600f58 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="8600f58" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Like no other					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-94d04f0" data-id="94d04f0" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                            </div>



                                        </section>
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-9f2940b elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="9f2940b" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-5ffca4e" data-id="5ffca4e" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-594df85" data-id="594df85" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-2778a97 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="2778a97" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Just what I need					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-298149d" data-id="298149d" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-ff18bd1 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="ff18bd1" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Great experience, very useful.	</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-c31a822" data-id="c31a822" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-160f72e elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="160f72e" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Saved me from hiring someone					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-44d3f60" data-id="44d3f60" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-5559292 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="5559292" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Feature-rich scheduler					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-16 elementor-inner-column elementor-element elementor-element-22eef73" data-id="22eef73" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-5023c5d elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5023c5d" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-f817983" data-id="f817983" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-816dd97 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="816dd97" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Saves a lot of time					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-4455a7d" data-id="4455a7d" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-d8605a8 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="d8605a8" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>		</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            A game changer					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-08f2ae7" data-id="08f2ae7" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-1cf236c elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="1cf236c" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Makes my life easier					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-4dad8eb" data-id="4dad8eb" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-39d753b elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="39d753b" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Super reasonable pricing					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-0c32459" data-id="0c32459" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-102661b elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="102661b" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Good for beginners					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-7283199 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7283199" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-0afa244" data-id="0afa244" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-707c4e1" data-id="707c4e1" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-e0af2f3 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="e0af2f3" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Valueable content with least effort.					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-8746662" data-id="8746662" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-8b50494 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="8b50494" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Great for planning content					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-bcd762d" data-id="bcd762d" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-5a0d0e0 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="5a0d0e0" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Extremely easy to use					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-a62bff0" data-id="a62bff0" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-1744713 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="1744713" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            VaSi is our favorite					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>

                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-5cec244 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="5cec244" data-element_type="section">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-4e571a9" data-id="4e571a9" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-2fe01a7" data-id="2fe01a7" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-dbad877 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="dbad877" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Number #1 reminder app					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-92e1de8" data-id="92e1de8" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-182e273 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="182e273" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>			</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Will be a loyal customer for a long time					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-406d818" data-id="406d818" data-element_type="column">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-f862cf5 elementor-view-stacked elementor-position-left elementor-vertical-align-bottom elementor-shape-circle elementor-mobile-position-top elementor-widget elementor-widget-icon-box" data-id="f862cf5" data-element_type="widget" data-widget_type="icon-box.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-icon-box-wrapper">
                                                                    <div className="elementor-icon-box-icon">
                                                                        <span className="elementor-icon elementor-animation-" >
                                                                            <i className="fa fa-star" aria-hidden="true"></i>				</span>
                                                                    </div>
                                                                    <div className="elementor-icon-box-content">
                                                                        <p className="elementor-icon-box-title">
                                                                            <span  >
                                                                            </span>
                                                                        </p>
                                                                        <p className="elementor-icon-box-description">
                                                                            Straightforward and stress-free					</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="elementor-column elementor-col-20 elementor-inner-column elementor-element elementor-element-b46d567" data-id="b46d567" data-element_type="column">
                                                    <div className="elementor-widget-wrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>



                        <section className="elementor-section elementor-top-section elementor-element elementor-element-c08db32 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="c08db32" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" id="section1">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="1117567" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-ea223a7 elementor-widget elementor-widget-heading" data-id="ea223a7" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">Create Dots</h3>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-284613a elementor-widget elementor-widget-heading" data-id="284613a" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h4 className="elementor-heading-title elementor-size-default">Let your creativity flow with Dots</h4>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-4bfb451 elementor-widget elementor-widget-text-editor" data-id="4bfb451" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>Step into a world of limitless possibilities with VaSi's Create Dot feature. Our robust and intuitive rich editor empowers you to bring your ideas to life like never before. Whether it's a task, habit, idea, or unique insight, you have the tools to create a Dot that shines.</p>

                                                    <p>With VaSi's rich editor, you're in control. Seamlessly format your text, enhance it with images and multimedia, and organize your Dot with ease. Tailor your Dot to match your vision, making it a true reflection of your style and purpose.</p>

                                                    <p>
                                                        But it doesn't stop there. Once your Dot is perfected, share it with the world. Contribute to the ever-growing VaSi community, inspire others, and unlock new possibilities through the exchange of knowledge and experiences.
                                                    </p>

                                                    <p>
                                                        Seize the opportunity to create dots that make a lasting impact. Embrace the art of crafting, customizing, and sharing with VaSi's rich editor at your fingertips. Let your ideas flourish and your Dot become a beacon of inspiration.
                                                    </p>
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element " data-id="9ff998a" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        {/* <div className="elementor-element elementor-element-3a9ae52 elementor-widget elementor-widget-image" data-id="3a9ae52" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container">

                                                <img decoding="async" width="300" height="102" src={WritewithAI} alt="Write with AI" />
                                            </div>
                                        </div> */}
                                        <div className="elementor-element elementor-element-c8045ad elementor-widget elementor-widget-image featured-section" data-id="c8045ad" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container ">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <img decoding="async" width="768" height="692" src={createDotImg} alt="AI Writing Assistant in VaSi" />
                                                </ScrollAnimation>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>





                        <section className="elementor-section elementor-top-section elementor-element elementor-element-88a8294 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="88a8294" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" id="section2">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element " data-id="3ecc43f" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-74d3b98 elementor-widget elementor-widget-image featured-section" data-id="74d3b98" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container ">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <img decoding="async" width="768" height="644" src={askVasi} className="attachment-medium_large size-medium_large wp-image-35613" alt="Design with Canva in VaSi" />
                                                </ScrollAnimation>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="3eb0ee1" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-2004a0b elementor-widget elementor-widget-heading" data-id="2004a0b" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">ASKVASI</h3>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-9272d71 elementor-widget elementor-widget-heading" data-id="9272d71" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h4 className="elementor-heading-title elementor-size-default">Ignite Your Ideas with AskVaSi</h4>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-77b6d38 elementor-widget elementor-widget-text-editor" data-id="77b6d38" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>Enter the realm of AskVaSi, where your imagination takes center stage and inspiration knows no bounds. Powered by cutting-edge Generative AI, AskVaSi empowers you to unlock a world of infinite possibilities.
                                                    </p>

                                                    <p>With AskVaSi, all you need is a prompt, and a wealth of dots will flow forth. Spark your creativity, solve problems, and explore new ideas as AskVaSi generates unique and personalized dots tailored to your needs.</p>
                                                    <p>
                                                        Whether you're seeking guidance, brainstorming fresh concepts, or looking for a dose of inspiration, AskVaSi is your creative companion. Tap into its extraordinary capabilities and watch as it transforms your prompts into a universe of valuable dots.

                                                    </p>
                                                    <p>
                                                        Unleash the potential of your thoughts and embark on an exciting journey of discovery with AskVaSi. Let the power of Generative AI ignite your imagination and propel you toward new horizons of creativity.
                                                    </p>
                                                </ScrollAnimation>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>



                        <section className="elementor-section elementor-top-section elementor-element elementor-element-7375290 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7375290" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" id="section3">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="099f1c4" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-de49654 elementor-widget elementor-widget-heading" data-id="de49654" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default"> REMINDERS</h3>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-812170a elementor-widget elementor-widget-heading" data-id="812170a" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h4 className="elementor-heading-title elementor-size-default">Stay on Track: VaSi's Essential Reminders.</h4>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-f52dc75 elementor-widget elementor-widget-text-editor" data-id="f52dc75" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>With VaSi's powerful Reminders feature, never miss a beat in your busy life. Stay on track and maximize your productivity with timely nudges and notifications that keep you focused and organized.</p>
                                                    <p>Effortlessly set time-based, location-based, calendar-based, and free time reminders to suit your unique needs. Let VaSi be your reliable companion, gently reminding you of important tasks, habits, and appointments, so you can effortlessly manage your commitments. </p>
                                                    <p>
                                                        Experience the transformative power of Reminders as they guide you through your day, ensuring you never overlook crucial deadlines or forget essential activities. Take control of your time and harness the full potential of your productivity with VaSi's intuitive Reminders feature by your side.

                                                    </p>
                                                </ScrollAnimation>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element " data-id="06a29ae" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">

                                        <div className="elementor-element elementor-element-00ab7b3 elementor-widget elementor-widget-image featured-section" data-id="00ab7b3" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container ">

                                                <ScrollAnimation threshold={200} animationDuration={1}>

                                                    <img decoding="async" width="768" height="588" src={vasiReminder} className="attachment-medium_large size-medium_large wp-image-35605" alt="Posting Schedule VaSi" />
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>





                        <section className="elementor-section elementor-top-section elementor-element elementor-element-88a8294 elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="88a8294" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" id="section4">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element " data-id="3ecc43f" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-74d3b98 elementor-widget elementor-widget-image featured-section" data-id="74d3b98" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container ">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <img decoding="async" width="768" height="644" src={vasiSearch} className="attachment-medium_large size-medium_large wp-image-35609" alt="Social Media Post Variation with Customization in VaSi" />
                                                </ScrollAnimation>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="3eb0ee1" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-2004a0b elementor-widget elementor-widget-heading" data-id="2004a0b" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default">MARKETPLACE</h3>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-9272d71 elementor-widget elementor-widget-heading" data-id="9272d71" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h4 className="elementor-heading-title elementor-size-default">Explore the power of many minds</h4>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-77b6d38 elementor-widget elementor-widget-text-editor" data-id="77b6d38" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>Dive into the world of Dots, VaSi's immersive exploration feature within the MarketPlace. Experience a treasure trove of unique insights, ideas, and plans curated by the vibrant VaSi community. </p>

                                                    <p>Tap into the collective wisdom of fellow users as you browse through a vast array of dots waiting to inspire you. From innovative tasks and habits to thought-provoking ideas and plans, MarketPlace is your gateway to endless possibilities.</p>

                                                    <p>
                                                        Immerse yourself in a diverse ecosystem of dots, each offering a glimpse into the creativity and expertise of the VaSi community. Gain fresh perspectives, discover hidden gems, and find dots that resonate with your goals and aspirations.
                                                    </p>
                                                    <p>
                                                        With MarketPlace, you have the power to unlock new horizons of knowledge, fuel your own ideas, and connect with like-minded individuals. Embark on a journey of exploration, elevate your own dots, and become an integral part of the thriving MarketPlace community.
                                                    </p>
                                                </ScrollAnimation>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>




                        <section className="elementor-section elementor-top-section elementor-element elementor-element-7375290 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7375290" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}" id="section5">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="099f1c4" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-de49654 elementor-widget elementor-widget-heading" data-id="de49654" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h3 className="elementor-heading-title elementor-size-default"> Monetize</h3>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-812170a elementor-widget elementor-widget-heading" data-id="812170a" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h4 className="elementor-heading-title elementor-size-default">Monetize Your Unique Insights and Ideas.</h4>		</div>
                                        </div>
                                        <div className="elementor-element elementor-element-f52dc75 elementor-widget elementor-widget-text-editor" data-id="f52dc75" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <p>Welcome to the MarketPlace that empowers you to monetize your invaluable dots. Transform your creativity into a valuable resource and share your unique insights, ideas, plans, and experiences with the world.</p>

                                                    <p>With MarketPlace, you have the opportunity to turn your dots into profit. Showcase your expertise, set your price, and connect with a community of eager buyers ready to tap into your wisdom. Whether you're a seasoned professional or an aspiring creator, MarketPlace provides a platform to monetize your intellectual capital.
                                                    </p>
                                                    <p>
                                                        Join the vibrant ecosystem of Dot sellers, where innovation thrives and success is rewarded. Tap into the immense potential of MarketPlace, reach a wider audience, and unlock new opportunities for growth and financial independence.

                                                    </p>
                                                    <p>
                                                        Don't let your dots go to waste. Empower yourself through MarketPlace, where your insights become valuable assets, and your passion for sharing knowledge can be turned into a rewarding endeavor. Start your Dot-selling journey today and redefine the way you monetize your expertise.
                                                    </p>
                                                </ScrollAnimation>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element" data-id="06a29ae" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">

                                        <div className="elementor-element elementor-element-00ab7b3 elementor-widget elementor-widget-image featured-section" data-id="00ab7b3" data-element_type="widget" data-widget_type="image.default">
                                            <div className="elementor-widget-container ">

                                                <ScrollAnimation threshold={200} animationDuration={1}>
                                                    <img decoding="async" width="768" height="644" src={vasiMarketplace} className="attachment-medium_large size-medium_large wp-image-35615" alt="Social Media Analytics VaSi" />
                                                </ScrollAnimation>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>







                        <section className="elementor-section elementor-top-section elementor-element elementor-element-618931e elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="618931e" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;,&quot;shape_divider_top&quot;:&quot;tilt&quot;}">
                            <div className="elementor-shape elementor-shape-top" data-negative="false">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                                    <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                                </svg>		</div>
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-6c28692" data-id="6c28692" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <section className="elementor-section elementor-inner-section elementor-element elementor-element-47b7bdb elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="47b7bdb" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                            <div className="elementor-container elementor-column-gap-default">
                                                <div className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-bfc6493" data-id="bfc6493" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classNameic&quot;}">
                                                    <div className="elementor-widget-wrap elementor-element-populated">
                                                        <div className="elementor-element elementor-element-f96a860 elementor-widget elementor-widget-jet-inline-svg" data-id="f96a860" data-element_type="widget" data-widget_type="jet-inline-svg.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-jet-inline-svg jet-elements">
                                                                    <div className="jet-inline-svg__wrapper">
                                                                        <div className="jet-inline-svg jet-inline-svg--custom-width"><div className="jet-inline-svg__inner">
                                                                            <svg width="98px" height="98px" viewBox="0 0 98 98" version="1.1" xmlns="http://www.w3.org/2000/svg" >

                                                                                <title>Quotation</title>
                                                                                <desc>Created with Sketch.</desc>
                                                                                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                                    <g id="Desktop-HD" transform="translate(-924.000000, -1970.000000)">
                                                                                        <g id="Group-13" transform="translate(741.000000, 1972.000000)">
                                                                                            <g id="Quotation" transform="translate(185.000000, 0.000000)">
                                                                                                <circle id="Oval-Copy" stroke="#FFFFFF" strokeWidth="4" fill="#F5C400" cx="47" cy="47" r="47"></circle>
                                                                                                <path d="M43.24,59.28 L43.24,45.96 L36.16,45.96 C36.16,44.6 36.26,43.22 36.46,41.82 C36.66,40.42 37.04,39.16 37.6,38.04 C38.16,36.92 38.88,36.02 39.76,35.34 C40.64,34.66 41.76,34.32 43.12,34.32 L43.12,28.32 C40.88,28.32 38.92,28.8 37.24,29.76 C35.56,30.72 34.18,32 33.1,33.6 C32.02,35.2 31.22,37.06 30.7,39.18 C30.18,41.3 29.92,43.56 29.92,45.96 L29.92,59.28 L43.24,59.28 Z M65.2,59.28 L65.2,45.96 L58.12,45.96 C58.12,44.6 58.22,43.22 58.42,41.82 C58.62,40.42 59,39.16 59.56,38.04 C60.12,36.92 60.84,36.02 61.72,35.34 C62.6,34.66 63.72,34.32 65.08,34.32 L65.08,28.32 C62.84,28.32 60.88,28.8 59.2,29.76 C57.52,30.72 56.14,32 55.06,33.6 C53.98,35.2 53.18,37.06 52.66,39.18 C52.14,41.3 51.88,43.56 51.88,45.96 L51.88,59.28 L65.2,59.28 Z" id="“" fill="#212533" fillRule="nonzero"></path>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </g>
                                                                            </svg></div></div></div></div>		</div>
                                                        </div>
                                                        <div className="elementor-element elementor-element-28590ad elementor-widget elementor-widget-testimonial" data-id="28590ad" data-element_type="widget" data-widget_type="testimonial.default">
                                                            <div className="elementor-widget-container">
                                                                <div className="elementor-testimonial-wrapper">

                                                                    <TestimonialSlider />


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>



                    </div>
                </div>
            </main >

            <FooterNew />
            <SchemaPage />
        </div>

    );
}

export default LandingPageNew;