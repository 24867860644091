import React, { useState, useEffect } from 'react';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import Axios from "axios"
//import Page from "./Page"
import HeaderLoggedOut from '../HeaderLoggedOut'
import FooterNew from '../FooterNew'
function ContactUs(props) {
    //console.log(props);
    const auth = getAuth();
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        message: ""
    };

    const [values, setValues] = useState(initialValues);
    const [errors, setErrors] = useState({});
    const [reCaptcha, setRecaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorResponse, setErrorResponse] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    useEffect(() => {
        //console.log(auth.currentUser);
        // if (auth.currentUser === null) {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container-feedback-contactus', {
            'size': 'visible',
            'callback': () => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                //this.onSignInSubmit();
                setRecaptcha(true)
                //setRecaptchaErr(false)
                //console.log(response);
            }
        }, auth);

        window.recaptchaVerifier.render().then((widgetId) => {
            window.recaptchaWidgetId = widgetId;
        }).catch((error) => {
            console.log(error);
        });
        //}


    }, [auth])


    const handleInputChange = (e) => {
        setErrors('')
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const validateForm = () => {

        let fields = values;
        let errors = {};
        let formIsValid = true;

        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Please enter your Name.";
        }


        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "An email is required.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "Please enter a valid Email.";
            }
        }

        /*if (!fields["phone"]) {
          formIsValid = false;
          errors["phone"] = "A phone number is required.";
        }*/

        /*if (typeof fields["phone"] !== "undefined") {
          if (!fields["phone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["phone"] = "*Please enter valid mobile no.";
          }
        }*/

        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Please enter some feedback.";
        }

        if (reCaptcha === false) {
            formIsValid = false;
            errors["recaptcha"] = "Solve the reCAPTCHA.";
        }

        setErrors(errors);
        //console.log(errors.name);
        return formIsValid;

    }

    const submitFeedback = (e) => {
        e.preventDefault()
        if (validateForm()) {
            setButtonDisabled(true)
            const URL = process.env.REACT_APP_BACKENDURL + 'contactSupport';
            const ourRequest = Axios.CancelToken.source()
            const submitData = async () => {
                try {
                    const response = await Axios.post(URL, {
                        data: {
                            "name": values.name,
                            "email": values.email,
                            "phone": values.phone,
                            "message": values.message
                        }
                    },
                        { cancelToken: ourRequest.token });
                    const json = await response.data;
                    if (json.result.status === 200) {
                        setSuccess(true)
                        setValues(initialValues)
                        setButtonDisabled(false)
                    } else {
                        setErrorResponse(true)
                        setButtonDisabled(false)
                    }

                } catch (e) {
                    setButtonDisabled(false)
                    console.log("There was a problem or the request was cancelled.")
                    //setIsLoading(false);
                }
            }
            submitData();
        }
    }

    //console.log(errors);
    return (
        <div className=" home inner_pages_nav elementor-default elementor-kit-6677 elementor-page elementor-page-4722">
            <HeaderLoggedOut />
            <div className="container outer-page">
                <header className="masthead_inner">
                    <div className="container px-5 text-center">
                        <h1>Contact Us</h1>
                    </div>
                </header>
                <aside className="inner_pages">
                    <div className="container px-5">
                        <div className="row gx-5">
                            <div className="col-md-6 mx-auto mb-3">

                                {success ? (
                                    <div className="text-center mb-3">
                                        <div className="alert alert-success">Your feedback has been submitted successfully.</div>
                                    </div>
                                ) :

                                    <form id="contactForm">

                                        <div className="form-floating mb-3">
                                            <input value={values.name} onChange={handleInputChange} name="name" className="form-control" id="name" type="text" placeholder="Enter your name..." data-sb-validations="required" autoComplete='off' />
                                            <label htmlFor="name">Full Name</label>
                                            <div className="invalid-feedback" data-sb-feedback="name:required">{errors.name}</div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input value={values.email} onChange={handleInputChange} className="form-control" name="email" id="email" type="email" placeholder="name@example.com" data-sb-validations="required,email" autoComplete='off' />
                                            <label htmlFor="email">Email Address</label>
                                            {errors === 'emailError' && (
                                                <div className="invalid-feedback" data-sb-feedback="email:required">{errors.email}</div>
                                            )}
                                            <div className="invalid-feedback" data-sb-feedback="email:email">{errors.email}</div>
                                        </div>

                                        <div className="form-floating mb-3">
                                            <input value={values.phone} onChange={handleInputChange} name="phone" className="form-control" id="phone" type="tel" placeholder="(123) 456-7890" autoComplete='off' />
                                            <label htmlFor="phone">Phone Number</label>
                                            {/*<div className="invalid-feedback" data-sb-feedback="phone:required">A phone number is required.</div>*/}
                                        </div>

                                        <div className="form-floating mb-3">
                                            <textarea value={values.message} onChange={handleInputChange} maxLength="300" name="message" className="form-control" id="message" type="text" placeholder="Enter your message here..." style={{ height: "10rem" }} data-sb-validations="required" autoComplete='off'></textarea>
                                            <label htmlFor="message">Message</label>
                                            <div className="invalid-feedback" data-sb-feedback="message:required">{errors.message}</div>
                                        </div>
                                        <div className="form-floating mb-3">
                                            <div id='recaptcha-container-feedback-contactus'></div>
                                            <div className="invalid-feedback" data-sb-feedback="message:required">{errors.recaptcha}</div>
                                        </div>
                                        {errorResponse && (
                                            <div id="submitErrorMessage"><div className="text-center text-danger mb-3">Error sending feedback!</div></div>
                                        )}
                                        <div className="d-grid">
                                            <button disabled={isButtonDisabled} onClick={submitFeedback} className="btn btn-primary rounded-pill btn-lg submit-contact" id="submitButton" type="submit">Submit</button>
                                        </div>
                                    </form>

                                }

                            </div>
                            <div className="col-md-6 mx-auto">

                                <iframe title="VaSi" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50695.76055472572!2d-122.17654957205838!3d37.4256428510377!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808fb07b9dba1c39%3A0xe1ff55235f576cf!2sPalo%20Alto%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1688724593849!5m2!1sen!2sin" width="100%" height="450" style={{ border: "0" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                            </div>
                        </div>
                    </div>
                </aside>
            </div>
            <FooterNew />
        </div>






    );
}

export default ContactUs;