import React from 'react';
import Slider from "react-slick";
import featured1 from '../assets/images/featured/featured1.png'
import featured2 from '../assets/images/featured/featured2.png'
import featured3 from '../assets/images/featured/featured3.png'
import featured4 from '../assets/images/featured/featured4.png'
import featured5 from '../assets/images/featured/featured5.png'
import featured6 from '../assets/images/featured/featured6.png'

import arrowRight from '../assets/images/arrow-right-1.png'
import arrowLeft from '../assets/images/arrow-left-1.png'

const FeaturedDotsCarousel = () => {


    const items = [
        {
            id: 1,
            image: featured1,
            caption: 'Monetise your habits',
            link: 'https://learnee.page.link/4caLie2RtaXs2iK28'
        },
        {
            id: 2,
            image: featured2,
            caption: 'Simply drinking',
            link: 'https://learnee.page.link/WzFDd7UegzN5Ychx9'
        },
        {
            id: 3,
            image: featured3,
            caption: 'Power of sunlight',
            link: 'https://learnee.page.link/mJGC9qMS9UQ7qzpe6'
        },

        {
            id: 4,
            image: featured4,
            caption: 'How to love yourself',
            link: 'https://learnee.page.link/8Gi18Jr7C3CBYcCL8'
        },
        {
            id: 5,
            image: featured5,
            caption: 'Schedule for success',
            link: 'https://learnee.page.link/kw1yDuHwStXMwF3JA'
        },
        {
            id: 6,
            image: featured6,
            caption: 'How to be happy',
            link: 'https://learnee.page.link/P6mbyrTAGL9VPzJz5'
        },

    ];

    // const [currentSlide, setCurrentSlide] = useState(0);
    // const [zoomedSlides, setZoomedSlides] = useState([]);

    // useEffect(() => {
    //     const middleSlides = document.querySelectorAll('.slick-slide.slick-active');

    //     setZoomedSlides([...middleSlides].map((slide) => slide.dataset.index));
    // }, [currentSlide]);

    // const handleBeforeChange = (oldIndex, newIndex) => {
    //     setCurrentSlide(newIndex);
    // };

    // const handleAfterChange = () => {
    //     const middleSlides = document.querySelectorAll('.slick-slide.slick-active');

    //     setZoomedSlides([...middleSlides].map((slide) => slide.dataset.index));
    // };

    // const CustomPrevArrow = (props) => {
    //     const { className, onClick } = props;
    //     return <div className={`prev-arrow-slick ${className}`} onClick={onClick} />;
    // };

    // const CustomNextArrow = (props) => {
    //     const { className, onClick } = props;
    //     return <div className={`next-arrow-slick ${className}`} onClick={onClick} />;
    // };

    const CustomPrevArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style,  zIndex: 1 }} // Adjust the positioning and styles as needed
                onClick={onClick}
            >
               <span className="arrowPrev"><img alt='featured' src={arrowLeft}></img></span>  {/* Use your custom left arrow icon here */}
            </div>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, zIndex: 1 }} // Adjust the positioning and styles as needed
                onClick={onClick}
            >
                <span className="arrowNext"><img src={arrowRight}></img></span> {/* Use your custom right arrow icon here */}
            </div>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 3000,
        // lazyLoad: true,
        pauseOnHover: true,
        lazyLoad: 'ondemand',
        // Add more settings as needed
        prevArrow: <CustomPrevArrow />, // Add the custom left arrow component
        nextArrow: <CustomNextArrow />, // Add the custom right arrow component
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20px'
                },
            },
        ]
    };

    //console.log(breakpoints[2]['itemsToShow']);
    const renderCarouselItems = () => {
        return items.map((item, index) => (

            <div key={item.id}>
                <div className="elementor-widget-wrap elementor-element-populated ">
                    <div className="elementor-element elementor-element-a3e395d elementor-widget elementor-widget-image" data-id="a3e395d" data-element_type="widget" data-widget_type="image.default">
                        <div className="elementor-widget-container carousel-item">
                            <a target='_blank' rel="noreferrer" href={item.link}>

                                <div className='image-slide card justify-content-center featured-card'>
                                    <img className={`slider_img_border img-fluid`} src={item.image} alt={`${item.caption}`} width="720px" height="720px" />
                                </div>
                                {/* <figcaption className="widget-image-caption wp-caption-text pb-10">{item.caption}</figcaption> */}

                            </a>
                        </div>
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <div className="container">
            <Slider {...settings} className="image-carousel">
                {renderCarouselItems()}
            </Slider>
        </div>

    );
};

export default FeaturedDotsCarousel;
