import React, { useEffect } from 'react';
import HeaderLoggedOut from "../HeaderLoggedOut"
import FooterNew from "../FooterNew"
import FAQ from "../FAQ"
function FAQPage() {
    useEffect(() => {
        document.title = `FAQ | VasiApp`
        window.scrollTo(0, 0)
    }, [])
    return (
        <div className="home inner_pages_nav elementor-default elementor-kit-6677 elementor-page elementor-page-4722">
            <HeaderLoggedOut />
            <div className="container outer-page">
                <header className="masthead_inner">
                    <div className="container px-5 text-center">
                        <h1>FAQ</h1>
                    </div>
                </header>
                <aside className="inner_pages">
                    <div className="container px-5">
                        <div className="row gx-5">
                           <FAQ />

                        </div>
                    </div>
                </aside>
            </div>
            <FooterNew />
        </div>
    );
}

export default FAQPage;
