import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// Configure Firebase.
const config = {
    apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
    authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASEPROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASESTORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASEMESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASEAPPID
  };
  firebase.initializeApp(config);

  export default firebase;