import React, { useEffect, useState } from 'react';
import Axios from "axios"
import { sha256 } from 'js-sha256';
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import firebase from '../Firebase'
import OTPInput, { ResendOTP } from "otp-input-react";
import { Navigate, Link } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { countryCode, countryList } from "./utils"

//import UserAlertPopup from '../components/UserAlertPopup';
import logo from "../assets/images/logo_updated.png"
import img_IN from "../assets/images/flags/India.png"
//import img_US from "../assets/images/flags/US.png"
//import login_page from "../assets/images/login_page.png"
//import login_page_m from "../assets/images/login_page_m.png"

function LoginScreen() {
    const defautIsdCode = "+91";
    const [showOtpScreen, setOtpScreen] = useState(false)
    const [mobile, setMobile] = useState("")
    const [isdCode, setIsdCode] = useState(defautIsdCode)
    const [countryImg, setCountryImg] = useState(img_IN)
    const [OTP, setOTP] = useState("");
    const [reCaptcha, setRecaptcha] = useState(false);
    const [mobileErr, setMobileErr] = useState(false);
    const [reCaptchaErr, setRecaptchaErr] = useState(false);
    const [otpErr, setotpErr] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    //const [redirectToPage, setRedirectTo] = useState(false);
    const [readOnlyPhone, setreadOnlyPhone] = useState(false)
    const [userDoesNotExist, setUserDoesNotExist] = useState(false)
    //const [alertPoupShow, setAlertPoupShow] = useState(false);
    //const [alertContent, setAlertContent] = useState("");

    //let propsData = '';
    //let navigate = useNavigate();
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.    

    /*const handleAlertPoupClose = () => {
        setAlertPoupShow(false);
    }*/

    /*const alertPoupPopupShow = (url) => {
        setAlertPoupShow(true);
    }*/

    const options = countryList();
    //const redirectionUrl = localStorage.getItem('redirectionUrl');
    const redirectTo = '/dashboard';//((redirectionUrl === '' || redirectionUrl === null || redirectionUrl === '/login') ? '/' : redirectionUrl);
    const auth = getAuth();
    let textInput = null;
    useEffect(() => {
        const mobileNumber = '';//localStorage.getItem('m');
        const mobileIsd = '';//localStorage.getItem('i');
        if (mobileNumber) {
            setMobile(mobileNumber);
            setreadOnlyPhone(true);
        }
        if (mobileIsd) {
            setIsdCode('+' + mobileIsd)
            var c_image = countryCode(mobileIsd);
            setCountryImg(c_image.img)
        }
        if (auth.currentUser === null) {
            window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
                'size': 'visible',
                'callback': (response) => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    //this.onSignInSubmit();
                    setRecaptcha(true)
                    setRecaptchaErr(false)
                    //console.log(response);
                }
            }, auth);

            window.recaptchaVerifier.render().then((widgetId) => {
                window.recaptchaWidgetId = widgetId;
            }).catch((error) => {
                console.log(error);
            });
        }


    }, [auth])

    const handleChangeIsdCode = (id, image) => {
        //const { value } = e.target
        setIsdCode(id)
        var c_image = countryCode(id.replace("+", ""));
        setCountryImg(c_image.img)
        //setCountryImg(image)
        textInput.focus();
    }

    const handleChangeNumber = (e) => {
        setMobileErr(false)
        const { value } = e.target
        setMobile(value)
    }

    const onSignInSubmit = (e) => {
        e.preventDefault();
        if (mobile === "" || mobile.length < 10) {
            setMobileErr(true)
            return;
        };
        if (reCaptcha === false) {
            setRecaptchaErr(true)
            return
        };
        setButtonDisabled(true)
        IfUserAlreadyReg();
    }

    const sentOtpCode = () => {
        const isd_code = (isdCode === "" ? defautIsdCode : isdCode)
        const phoneNumber = isd_code + mobile;
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                //console.log(confirmationResult);
                localStorage.setItem('i', isd_code);
                setOtpScreen(true)
                setButtonDisabled(false)
                //this.onSubmitOtp();
            }).catch((error) => {
                // Error; SMS not sent
                //console.log(error.message)
                //window.recaptchaVerifier.clear()
                setRecaptcha(false)
                setMobileErr(true)
                setButtonDisabled(false)
                console.log('OTP Not Sent');
            });
    }

    const IfUserAlreadyReg = async () => {
        //console.log(userDoesNotExist)
        try {
            const ourRequest = Axios.CancelToken.source()
            const URL = process.env.REACT_APP_BACKENDURL + 'getUserProfileInfo';
            const user_id = sha256(mobile);
            const response = await Axios.post(URL, {
                data: {
                    "user_id": user_id
                }
            },
                { cancelToken: ourRequest.token });
            if (response.data.result.status === 200) {
                sentOtpCode()
            } else {
                //setRecaptcha(false)                
                setButtonDisabled(false)
                setUserDoesNotExist(true)
                //console.log(userDoesNotExist)
                //setRedirectTo(true);                
            }
            //console.log(response.data);
        } catch (e) {
            console.log(e)
        }

    }


    const handleOtp = (otp) => {
        setotpErr(false);
        setOTP(otp)
    }

    //console.log(OTP);
    const onSubmitOtp = (e) => {
        e.preventDefault();
        //console.log(auth);
        const code = OTP;
        setButtonDisabled(true)
        window.confirmationResult.confirm(code).then((result) => {
            localStorage.removeItem('m');
            //localStorage.removeItem('i');
            // User signed in successfully.
            const user = result.user;
            setIsSignedIn(user);
            //console.log(user);
            // navigate(redirectTo);
            setButtonDisabled(false)
        }).catch((error) => {
            setotpErr(true);
            setButtonDisabled(false)
            console.log("bad verification code?");
            // User couldn't sign in (bad verification code?)
            // ...
        });
    }

    const resendOTP = () => {
        setOTP("")
        setOtpScreen(false);
    }

    const cancelOTP = () => {
        setMobile("")
        setCountryImg(img_IN)
        setIsdCode(defautIsdCode)
        setOTP("")
        setOtpScreen(false);
    }

    const renderButton = (buttonProps) => {
        //console.log(buttonProps);
        return (
            <button {...buttonProps} className='resend-btn'>
                {buttonProps.remainingTime !== 0 ? `Resend code in ${buttonProps.remainingTime} sec` : "Resend"}
            </button>
        );
    };
    const renderTime = () => React.Fragment;



    // if (redirectToPage) {
    //     return <Navigate to='/guest-user' />
    // }

    if (!isSignedIn && auth.currentUser === null) {
        return (
            <div className="container justify-content-center align-items-center login-form">
                <div className='row'>
                    {/* <div className='col-md-6 col-ms-12 login-page-l'>
                        <div className='p-top-13 align-items-center text-sm-center'>
                            <h1>Welcome to Vasi!</h1>
                            <h6>We’ll remind you whenever & wherever you need</h6>
                            <div className='login-img'></div>
                          
                        </div>

                    </div> */}
                    <div className='mt-h  login-page'>
                        <div className='login-p'>
                            <form className={!showOtpScreen ? ' login-f' : 'login-f  loginform-hidden'}>
                                <div className="half-circle"> <div className="text-center p-sm-3 form-floating mb-1"> 
                                <Link to="/">
                                <img className="logo-l" alt="logo" src={logo} width="60" /> </Link>
                                </div></div>
                                <div className="card login-card p-sm-5">

                                    <div className="text-center mt-3 mb-3"> <h5>Enter your phone number</h5> </div>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <DropdownButton align="end" title={<div className="pull-left">
                                                <img src={countryImg} alt="flag" />  {isdCode}
                                            </div>} id="dropdown-menu-align-end">
                                                {options.map((option, index) => (
                                                    <Dropdown.Item disabled={readOnlyPhone} eventKey="1" onClick={() => handleChangeIsdCode(option.id, option.img)} key={index}>
                                                        <img src={option.img} alt={option.title} />&nbsp; &nbsp; {option.title}&nbsp;{option.id}
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </div>
                                        <input readOnly={readOnlyPhone} placeholder='Phone Number' ref={(input) => { textInput = input; }} className="form-control" value={mobile} name="mobile_number" onChange={handleChangeNumber} required />
                                        {mobileErr && (
                                            <div className="invalid-feedback-error">
                                                Enter a valid phone number.
                                            </div>
                                        )}
                                    </div>

                                    <div className="position-relative mt-3 form-input login-captcha">
                                        <div id="recaptcha-container"></div>

                                        {reCaptchaErr && (
                                            <div className="invalid-feedback-error">
                                                Solve the reCAPTCHA.
                                            </div>
                                        )}
                                    </div>

                                    {userDoesNotExist &&
                                        
                                            <div className="invalid-feedback-error text-center">
                                                Only Admin user allowed!
                                            
                                        </div>
                                    }

                                    <div className="mobile-text-btm mt-3">By tapping Verify, an SMS may be sent. Message & data rates may apply.</div>
                                    <div className="mt-3 d-flex justify-content-between align-items-center">
                                        <button disabled={isButtonDisabled} onClick={onSignInSubmit} className={"btn btn-dark div-center margin-auto btn-lg" + (isButtonDisabled ? ' loader-btn' : '')}>VERIFY</button>
                                    </div>

                                </div>
                            </form>

                            {showOtpScreen && (
                                <form className='mt-5 login-f login-otp'>
                                    <div className="half-circle"> <div className="text-center p-sm-3 form-floating"> <img className="logo-l" alt="logo" src={logo} width="60" /> </div></div>
                                    <div className="otp-form-l">
                                        <div className="card py-5 login-card otp-card text-center">
                                            <h5 className="m-0">Verify your phone number</h5><div className="mobile-text mt-2">Enter the 6-digit code we sent to
                                                <div><strong>{isdCode === "" ? " " + defautIsdCode : isdCode} {mobile}</strong></div></div>
                                            <div className="d-flex flex-row mt-5 margin-auto">
                                                <OTPInput
                                                    value={OTP}
                                                    onChange={handleOtp}
                                                    autoFocus
                                                    OTPLength={6}
                                                    otpType="number"
                                                    disabled={false}
                                                    className="otp-form"
                                                    inputClassName="form-control-otp"
                                                    inputStyles={{ height: "100%" }}
                                                />

                                            </div>
                                            {otpErr && (
                                                <div className="invalid-feedback-error">
                                                    Enter valid code.
                                                </div>
                                            )}

                                            <div className="mt-5 d-flex justify-content-between margin-auto align-items-center">
                                                <button type="button" className="btn btn-cancel margin-right-10" onClick={() => cancelOTP()}>CANCEL</button>
                                                <button disabled={isButtonDisabled} onClick={onSubmitOtp} className={"btn btn-primary " + (isButtonDisabled ? 'loader-btn' : '')}>CONTINUE</button>
                                            </div>
                                            <div className="text-center mt-4"><span className="d-block mobile-text">Don't receive the code?</span>
                                                <span className="font-weight-bold text-danger cursor ">
                                                    <ResendOTP renderButton={renderButton} renderTime={renderTime} maxTime={20} onResendClick={() => resendOTP()} />
                                                    {/*<ResendOTP maxTime={3} onResendClick={() => resendOTP()} />*/}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
    return (
        <div>
            <Navigate to={redirectTo} />
        </div>
    );
}

export default LoginScreen;