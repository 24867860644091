//import React from "react";
import ReactGA from "react-ga4";

const useAnalyticsEventTracker = (category="Category") => {
    console.log(category);
  const eventTracker = (action = "Click", label = "Button") => {
    ReactGA.event({category, action, label});
  }
  return eventTracker;
}
export default useAnalyticsEventTracker;