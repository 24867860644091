import React from 'react';
import Slider from "react-slick";

const VideoCarousel = () => {
    const videoUrls = [
        'https://www.youtube.com/embed/tzn_z9Q_oWQ',
        'https://www.youtube.com/embed/lfivU9LYFsY'
    ];


    const settings = {
        className: "",
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    return (
        <div>
            <Slider {...settings}>
                {videoUrls.map((url, index) => (
                    <div key={index}>
                        <iframe
                            title={`Video ${index + 1}`}
                            src={url}
                            allowFullScreen                           
                            width="100%"
                            height="415"
                        ></iframe>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default VideoCarousel;
