import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom"
import logo from "../assets/images/logo_updated.png"
import logo_black from "../assets/images/logo_updated_black.png"


function HeaderLoggedOut(props) {

    const [menuVisible, setMenuVisible] = useState(true);
    const location = useLocation();
    // Determine if the current page is the details page based on the pathname
    const isDetailsPage = location.pathname.includes('/details');
    const isDotPage = location.pathname.includes('/dots');
    console.log(location.pathname);
    useEffect(() => {
        const checkMobile = () => {
            const mediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the media query based on your mobile device requirements
            console.log(mediaQuery.matches);
            if (mediaQuery.matches) {
                setMenuVisible(false);
            } else {
                setMenuVisible(true);
            }

        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => {
            window.removeEventListener('resize', checkMobile);
        };
    }, []);

    const toggleMenu = () => {
        console.log(menuVisible);
        setMenuVisible(!menuVisible);
    };


    return (

        <nav className="navbar sticky-top navbar-expand-lg">
            <div className="container-fluid top-menu-btn">
                <Link className="navbar-brand" to="/">
                    <img src={isDetailsPage || isDotPage ?logo_black:logo} alt="VaSi" width="60" height="40" />
                </Link>
                <button onClick={toggleMenu} className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    {menuVisible &&
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/">
                                    <span className="me-2"><i className="fas fa-home"></i></span>Home
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/about-us">About Us</Link>
                            </li>
                            {!isDetailsPage && !isDotPage && (
                                <React.Fragment>
                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to="/terms-of-service-usa">Terms of Service</Link>
                                    </li>

                                    <li className="nav-item">
                                        <Link className="nav-link" aria-current="page" to="/privacy-policy-usa">Privacy Policy</Link>
                                    </li>
                                </React.Fragment>
                            )}

                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/contact-us/">Contact Us</Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" aria-current="page" to="/faq/">FAQ</Link>
                            </li>
                        </ul>
                    }
                </div>

            </div>
        </nav>


    );
}

export default HeaderLoggedOut;