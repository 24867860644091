import React, { useEffect } from 'react';

const SchemaPage = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "VaSi",
            "description": "Measure your Productivity. Achieve your tasks and ideas by learning from the experts. Monetize your real life expertise and experiences.",
            "url": "https://www.vasi.ai/"
        });
        document.head.appendChild(script);

        // Clean up the script element on unmount if necessary
        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return (
        <></>
    );
};

export default SchemaPage;
