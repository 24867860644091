import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom"

import Create from '../assets/images/Create.png'
import AI from '../assets/images/AI.png'
import Market from '../assets/images/Market.png'
import Reminders from '../assets/images/Reminders.png'
import Sell from '../assets/images/Sell.png'

const BoxFocus = () => {

  const [focusedBoxIndex, setFocusedBoxIndex] = useState(0);
  const boxSectionRef = useRef(null);
  const [animationStarted, setAnimationStarted] = useState(false);
  const isPaused = useRef(false); // Ref to track the pause state

  const boxes = [
    {
      title: 'Create Dots',
      description: 'Empower Your Insights, Ideas, Plans.',
      image: Create,
    },
    {
      title: 'AskVasi',
      description: 'Prompt Your Creativity, Unleash Infinite Dots.',
      image: AI
    },
    {
      title: 'Reminders',
      description: 'Stay on Track, Achieve with Ease.',
      image: Reminders
    },
    {
      title: 'Marketplace',
      description: 'Unlock the Power of Your Dots, Connect, and Prosper.',
      image: Market
    },

    {
      title: 'Monetize',
      description: 'Earn through your ideas, experiences and thoughts',
      image: Sell
    },
  ];


  useEffect(() => {
    const handleScroll = () => {
      if (!boxSectionRef.current) return
      const { top, bottom } = boxSectionRef.current.getBoundingClientRect();
      const isVisible = top < window.innerHeight && bottom >= 0;

      if (isVisible && !animationStarted) {
        setAnimationStarted(true);
        setFocusedBoxIndex(0);
        animateFocus(0);
      }
    };

    const animateFocus = (index) => {
      //let loopIntervalId
      setFocusedBoxIndex(0); // Start with the first box
      //console.log(isPaused)
      // loopIntervalId =
      setInterval(() => {

        if (!isPaused.current) {
          setFocusedBoxIndex((prevIndex) => (prevIndex + 1) % boxes.length); // Move focus to next box in a loop
        }
      }, 2000);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);


  }, [animationStarted, isPaused]);

  const handleBoxHover = (index) => {
    //console.log(index)
    isPaused.current = true;
    //if (focusedBoxIndex === index) {
    // setFocusedBoxIndex(index);
    //} else {
    setFocusedBoxIndex(index);
    // }
  };

  const handleBoxLeave = () => {
    isPaused.current = false;
    //setFocusedBoxIndex(null);
  };


  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="elementor-container elementor-column-gap-default" ref={boxSectionRef}>
      {boxes.map((box, index) => (
        <div key={index} onMouseEnter={() => handleBoxHover(index)}
          onMouseLeave={handleBoxLeave} className={`elementor-column elementor-col-20 elementor-top-column elementor-element elementor-element-839dad5 box`} data-id="839dad5" data-element_type="column">
          {/* ${focusedBoxIndex === index ? 'focused' : ''} */}

          <div className={`${focusedBoxIndex === index ? 'focused' : ''}`}></div>

          <div className="elementor-widget-wrap elementor-element-populated">

            <div className="elementor-element elementor-element-c422c1d elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box" data-id="c422c1d" data-element_type="widget" data-widget_type="icon-box.default">

              
                <div className="elementor-widget-container height-260">
                <Link to="#" onClick={() => scrollToSection(`section${index + 1}`)} className="display-block" rel="noreferrer" aria-label="Manage your social media profiles">

                  <div className="elementor-icon-box-wrapper">

                    {focusedBoxIndex !== index && focusedBoxIndex !== null && (
                      <div className="overlay_boxes"></div>
                    )}
                    <div className="elementor-icon-box-icon">
                      <img decoding="async" src={box.image} alt="Create" />

                    </div>
                    <div className="elementor-icon-box-content">

                      <h2 className="elementor-icon-box-title">

                        {box.title}
                      </h2>
                      <p className="elementor-icon-box-description">
                        {box.description}
                      </p>
                    </div>
                  </div>
                  </Link>
                </div>

             
            </div>


          </div>
        </div>

        // <div
        //   key={index}
        //   className={`box ${focusedBoxIndex === index ? 'focused' : ''}`}
        // >
        //   Box {index + 1}
        // </div>
      ))}
    </div>
  );
};

export default BoxFocus;
